<template>
    <div>
        <p class="text text-margin">{{ $t('faq.tivimate.step5.youCanEnter') }}</p>
        <div class="screenshot">
            <img src="../assets/screen-3.png" alt="" class="step-image">
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
